.clear-filter[filter-color="black"] {
  @include linear-gradient(rgba($black-color,.20), rgb(63, 63, 63));
}
[data-background-color="green"]{
    background-color: $info-color;
}
.section-nucleo-icons .icons-container i:nth-child(6) {
  color: $info-color;
}
.page-header{
  &.page-header-small{
    min-height: 60vh;
    max-height: 640px;
  }
}
